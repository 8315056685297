<template>
  <div class="u-display-flex u-flex-direction-column">
    <section class="u-display-flex u-flex-direction-column u-">
      <loader
        v-if="isLoading"
        class="fill--parent"
        :loading="isLoading"
        :color="'#3684bb'"
      />
      <span class="u-font-size-5 u-font-weight-600 u-color-grey-base">
        {{ title }}:
      </span>
      <div class="u-spacing-mt-m u-display-flex">
        <!-- Search Ingrid -->
        <div
          v-for="key in Object.keys(defaultState)"
          :key="key"
          class="u-display-flex u-flex-direction-column u-spacing-mr-l"
        >
          <div class="u-display-flex u-flex-align-items-center u-spacing-mb-xs">
            <custom-tool-tip
              :tippy="{
                html: '#' + key,
                interactive: false,
                offset: '0,0',
                placement: 'top-start'
              }"
            >
              <div slot="toolTipData">
                <div
                  class="max-width-420px u-line-height-1-3 u-spacing-pv-l u-spacing-ph-m tippy-drop-down u-border-radius-s"
                >
                  <div class="u-spacing-pb-m u-font-weight-600 u-font-size-5">
                    {{ key }}
                  </div>
                  <span class="u-font-size-6 u-font-weight-400">{{
                    state[key].tooltipText
                  }}</span>
                </div>
              </div>
              <div
                slot="body"
                slot-scope="{ tippyConfig }"
              >
                <div
                  v-tippy="tippyConfig"
                  class="u-font-size-6 u-spacing-mr-xs"
                >
                  <rb-icon
                    class="u-flex-0 rb-icon--medium u-color-grey-lighter"
                    :icon="'info-circle-fill'"
                  />
                </div>
              </div>
            </custom-tool-tip>
            <span
              class="u-font-size-6 u-font-weight-600 u-color-grey-lighter"
              >{{ key }}</span
            >
          </div>
          <rb-select
            class="valueDropdown"
            :class="{ 'disabled pointer-events-none': state[key].disabled }"
            :send-details="true"
            :on-close="
              (context, action) => optionSelected(context, action, key)
            "
            :options="(state[key] || {}).options || []"
          >
            <div
              slot="trigger"
              class="u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer"
            >
              <div class="u-display-flex u-spacing-p-s">
                <span
                  class="u-spacing-mr-xs u-flex-align-self-center"
                  :class="[
                    state[key].defaultValue.title === 'Enabled'
                      ? 'enabled-circle'
                      : 'disabled-circle'
                  ]"
                />
                <span class="u-font-size-5">{{
                  state[key].defaultValue.title
                }}</span>
              </div>
              <rb-icon
                class="rb-icon--medium u-spacing-mh-s u-color-grey-base"
                :icon="state[key].disabled ? 'lock' : 'caret-down'"
              />
            </div>
            <template
              slot="item"
              slot-scope="option"
            >
              <div class="u-display-flex u-flex-align-items-center">
                {{ option.title }}
              </div>
            </template>
          </rb-select>
        </div>
      </div>
    </section>
    <section
      v-if="!disableLeftButton"
      class="u-display-flex"
    >
      <div class="u-spacing-pt-xl">
        <footerButtons
          :button-min-width="'u-min-width-100px'"
          :disable-left-btn="disableLeftButton"
          :left-button-type="disableLeftButton ? 'filled' : 'hollow'"
          @onClickBtnRight="onCancel"
          @onClickBtnLeft="onSave"
        />
      </div>
    </section>
  </div>
</template>
<script>
import { cloneDeep, isEqual } from 'lodash';
import footerButtons from '@/pages/entity_details/common_components/footerButtons.vue';
import loader from '@/components/basic/loader';
import customToolTip from '@/components/widgets/customToolTip.vue';

export default {
  components: {
    footerButtons,
    loader,
    customToolTip
  },
  props: {
    defaultState: {
      type: Object,
      default: () => ({})
    },
    title: {
      type: String,
      default: ''
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    state: {}
  }),
  computed: {
    disableLeftButton() {
      const disable = isEqual(this.defaultState, this.state);
      return disable;
    }
  },
  watch: {
    defaultState: {
      handler(nv) {
        this.state = cloneDeep(nv);
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.state = cloneDeep(this.defaultState);
  },
  methods: {
    optionSelected(context, action, key) {
      this.state[key].defaultValue = {
        title: action[0].title,
        value: action[0].value
      };
    },
    onSave() {
      const result = {};
      const context = this;
      Object.keys(this.state).forEach((key) => {
        if (!isEqual(context.state[key], context.defaultState[key])) {
          result[key] = context.state[key];
        }
      });
      this.$emit('onSave', result);
    },
    onCancel() {
      this.state = cloneDeep(this.defaultState);
    }
  }
};
</script>
<style lang="css" scoped>
.enabled-circle {
  border-radius: 50%;
  height: 8px;
  width: 8px;
  background-color: #3ea95e;
}
.disabled-circle {
  border-radius: 50%;
  height: 8px;
  width: 8px;
  background-color: #ffa800;
}

.pointer-events-none {
  pointer-events: none;
}
.max-width-420px {
  max-width: 420px;
}
</style>
