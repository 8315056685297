var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"u-display-flex u-flex-direction-column"},[_c('section',{staticClass:"u-display-flex u-flex-direction-column u-"},[(_vm.isLoading)?_c('loader',{staticClass:"fill--parent",attrs:{"loading":_vm.isLoading,"color":'#3684bb'}}):_vm._e(),_c('span',{staticClass:"u-font-size-5 u-font-weight-600 u-color-grey-base"},[_vm._v(" "+_vm._s(_vm.title)+": ")]),_c('div',{staticClass:"u-spacing-mt-m u-display-flex"},_vm._l((Object.keys(_vm.defaultState)),function(key){return _c('div',{key:key,staticClass:"u-display-flex u-flex-direction-column u-spacing-mr-l"},[_c('div',{staticClass:"u-display-flex u-flex-align-items-center u-spacing-mb-xs"},[_c('custom-tool-tip',{attrs:{"tippy":{
              html: '#' + key,
              interactive: false,
              offset: '0,0',
              placement: 'top-start'
            }},scopedSlots:_vm._u([{key:"body",fn:function(ref){
            var tippyConfig = ref.tippyConfig;
return _c('div',{},[_c('div',{directives:[{name:"tippy",rawName:"v-tippy",value:(tippyConfig),expression:"tippyConfig"}],staticClass:"u-font-size-6 u-spacing-mr-xs"},[_c('rb-icon',{staticClass:"u-flex-0 rb-icon--medium u-color-grey-lighter",attrs:{"icon":'info-circle-fill'}})],1)])}}],null,true)},[_c('div',{attrs:{"slot":"toolTipData"},slot:"toolTipData"},[_c('div',{staticClass:"max-width-420px u-line-height-1-3 u-spacing-pv-l u-spacing-ph-m tippy-drop-down u-border-radius-s"},[_c('div',{staticClass:"u-spacing-pb-m u-font-weight-600 u-font-size-5"},[_vm._v(" "+_vm._s(key)+" ")]),_c('span',{staticClass:"u-font-size-6 u-font-weight-400"},[_vm._v(_vm._s(_vm.state[key].tooltipText))])])])]),_c('span',{staticClass:"u-font-size-6 u-font-weight-600 u-color-grey-lighter"},[_vm._v(_vm._s(key))])],1),_c('rb-select',{staticClass:"valueDropdown",class:{ 'disabled pointer-events-none': _vm.state[key].disabled },attrs:{"send-details":true,"on-close":function (context, action) { return _vm.optionSelected(context, action, key); },"options":(_vm.state[key] || {}).options || []},scopedSlots:_vm._u([{key:"item",fn:function(option){return [_c('div',{staticClass:"u-display-flex u-flex-align-items-center"},[_vm._v(" "+_vm._s(option.title)+" ")])]}}],null,true)},[_c('div',{staticClass:"u-display-flex u-flex-align-items-center u-flex-justify-content-space-between u-cursor-pointer",attrs:{"slot":"trigger"},slot:"trigger"},[_c('div',{staticClass:"u-display-flex u-spacing-p-s"},[_c('span',{staticClass:"u-spacing-mr-xs u-flex-align-self-center",class:[
                  _vm.state[key].defaultValue.title === 'Enabled'
                    ? 'enabled-circle'
                    : 'disabled-circle'
                ]}),_c('span',{staticClass:"u-font-size-5"},[_vm._v(_vm._s(_vm.state[key].defaultValue.title))])]),_c('rb-icon',{staticClass:"rb-icon--medium u-spacing-mh-s u-color-grey-base",attrs:{"icon":_vm.state[key].disabled ? 'lock' : 'caret-down'}})],1)])],1)}),0)],1),(!_vm.disableLeftButton)?_c('section',{staticClass:"u-display-flex"},[_c('div',{staticClass:"u-spacing-pt-xl"},[_c('footerButtons',{attrs:{"button-min-width":'u-min-width-100px',"disable-left-btn":_vm.disableLeftButton,"left-button-type":_vm.disableLeftButton ? 'filled' : 'hollow'},on:{"onClickBtnRight":_vm.onCancel,"onClickBtnLeft":_vm.onSave}})],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }